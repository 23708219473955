import React, {useEffect, useState} from "react"

import "../styles/GreyHeader.css"
import {Container, Col, Tooltip, OverlayTrigger} from "react-bootstrap"
import {Book, Bookmark, Edit3, Eye, EyeOff, LogOut, PlusCircle, Search, User} from "react-feather"

import VoucherModal from "./VoucherModal"
import UserprofileModal from "./UserprofileModal";
import {useAuth} from "../authentication/auth"
import {BOOKS_URL, HOST_URL} from "../constants";

export function GreyHeader(props) {

    const {setAuthTokens} = useAuth()
    const [isVisible, setIsVisible] = useState(false)
    const [isVisibleSearch, setIsVisibleSearch] = useState(false)
    const [isVisibleModal, setIsVisibleModal] = useState(false)
    const {authTokens} = useAuth()

    window.showElementSearch = function() {
        window.PDFViewerApplication.findBar.toggle();
    }

    window.showElement = function() {
        window.hideSearch();
        setIsVisible(true)
    }
    function hideElement() {
        window.hideSearch();
        setIsVisible(false)
    }

    window.showElementModal = function() {
        window.hideSearch();
        loadProfile();
    }

    function hideElementModal() {
        window.hideSearch();
        setIsVisibleModal(false)
    }

    function logOut() {
        localStorage.clear()
        setAuthTokens(null)
        window.document.location.href="/facultas/";
    }

    //useEffect(() => {
    //    window.loadFirstBook(authTokens)}, [authTokens])

    window.setIsVisible = setIsVisible;

    function loadFirstBook(authTokens)
    {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + authTokens
            }
        }

        console.log('REQUEST OPTIONS')
        console.log(requestOptions)

        fetch(HOST_URL + BOOKS_URL, requestOptions)
            .then((response) => {
                console.log('RESPONSE')
                console.log(response)
                if(!response.ok) {
                    console.log('response not ok')
                    throw Error(response.status + ': ' + response.statusText)
                }
                return response.json()
            })
            .then((json) => {
                console.log(json.results)
                var loaded = false;
                for(var r in json.results)
                {
                    if(window.lastLoaded != json.results[r].eBook_pdf.replace("127.0.0.1","api.books.lereto.com"))
                    {
			window.currentBook = json.results[r];
                        window.fname = json.results[r].title;
                        window.loadFile(json.results[r].eBook_pdf.replace("127.0.0.1","api.books.lereto.com"));
                        window.fname = json.results[r].title;
                    }
			window.currentBook = json.results[r];
                        window.fname = json.results[r].title;
                        window.lastLoaded = json.results[r].eBook_pdf.replace("127.0.0.1","api.books.lereto.com");
                        window.fname = json.results[r].title;
                        window.updateFname();
                        loaded = true;
                        break;

                }

                if(!loaded)
                {
                    window.setIsVisible(true);
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    //loadFirstBook(authTokens);
    window.loadFirstBook = loadFirstBook;
    window.authTokens = authTokens;
    window.lastLoaded = "";



    var profile = "";

    function loadProfile(hidex)
    {
        window.jQuery.get("https://api.books.lereto.com/api/user/accountdata/" + JSON.parse(localStorage.tokens),function(data){
            window.profile2 = data;
            profile = data;



            /*
                <tr><td>Vorname: </td>          <td id="prf_vorname"></td></tr>
                        <tr><td>Nachname: </td>         <td id="prf_nachname"></td></tr>
                        <tr><td>MatrikelNummer:</td>    <td id="prf_matrikelnummer"></td></tr>
                        <tr><td>Studienrichtung:</td>   <td id="prf_studienrichtung"></td></tr>
                        <tr><td>Unternehmen:</td>       <td id="prf_unternehmen"></td></tr>
                        <tr><td>Firmenbuchnummer</td>   <td id="prf_fn"></td></tr>
                        <tr><td>Uni: </td>              <td id="prf_uni"></td></tr>
                        <tr><td>Profi: </td>            <td id="prf_profi">Ja</td></tr>
                        <tr><td>Email: </td>            <td id="prf_email"></td></tr>
                        <tr><td>Abo: </td>              <td id="prf_abo">|NAME| Läuft bis: </td></tr>
             */


if(!hidex)
{
            setIsVisibleModal(true);

            setTimeout(function(){
                window.$("#prf_vorname").text(window.profile2.vorname);
                window.$("#prf_nachname").text(window.profile2.nachname);
                window.$("#prf_matrikelnummer").text(window.profile2.matrikel_nr);
                window.$("#prf_studienrichtung").text(window.profile2.studienrichtung);
                window.$("#prf_unternehmen").text(window.profile2.unternehmen);
                window.$("#prf_fn").text(window.profile2.firmenbuchnummer);
                window.$("#prf_uni").text(window.profile2.uni);
                window.$("#prf_email").text(window.profile2.email);
                window.$("#prf_profi").text(window.profile2.professional);
                console.log("DONE");
            },100);
        }}

);




    }

    window.loadProfile = loadProfile;

    function renderTooltipSearch(props) {
        return (
            <div></div>
        )
    }

    function renderTooltip(props) {
        return (
            <Tooltip id="button-tooltip" {...props}>
                Inhaltsverzeichnis anzeigen
            </Tooltip>
        )
    }

    function renderTooltipMarks(props) {
        return (
            <Tooltip id="button-tooltip" {...props}>
                Eigene Markierungen
            </Tooltip>
        )
    }

    function renderTooltipAusblenden(props) {
        return (
            <Tooltip id="button-tooltip" {...props}>
                Fundstellen anzeigen / ausblenden
            </Tooltip>
        )
    }
    function renderTooltipProfil(props) {
        return (
            <Tooltip id="button-tooltip" {...props}>
                Benutzerprofil
            </Tooltip>
        )
    }

    function renderTooltipMyBooks(props) {
        return (
            <Tooltip id="button-tooltip" {...props}>
        Mein mybooklink
    </Tooltip>
    )
    }

    function renderTooltipAddBook(props) {
        return (
            <Tooltip id="button-tooltip" {...props}>
        eBook hinzufügen
    </Tooltip>
    )


    }

    function renderTooltipLogout(props) {
        return (
            <Tooltip id="button-tooltip" {...props}>
        Logout
    </Tooltip>
    )
    }

    return (
        <Container fluid className="GreyHeader">
            <Col className="nav-box nav-box-left">
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltipProfil}>
                    <div className="nav-icon-wrapper"  onClick={window.showElementModal}>
                        <User className="nav-icon" />
                    </div>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltipSearch}>
                    <div className="nav-icon-wrapper"   onClick={window.showElementSearch}>
                        <Search className="nav-icon" />
                    </div>
                </OverlayTrigger>
                <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 250 }}
            overlay={renderTooltipMyBooks}>
                <div className="nav-icon-wrapper" onClick={function(){props.drawerClickHandler();window.hideSearch();}}>
                <Book className="nav-icon" />
                </div>
                </OverlayTrigger>
            <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 250 }}
        overlay={renderTooltipAddBook}>
            <div className="nav-icon-wrapper" onClick={window.showElement}>
            <PlusCircle className="nav-icon" />
            </div>
            </OverlayTrigger>

            </Col>
            <Col className="book-box">
                <h1 className="book-title">{window.fname}</h1>
            </Col>
            <Col className="nav-box nav-box-right">
                <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltip}>
                    <div className="nav-icon-wrapper">
                        <Bookmark className="nav-icon" onClick={function(){window.toggleJumpFeature();}}/>
                    </div>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltipMarks}>
                    <div className="nav-icon-wrapper" onClick={function(){window.hideSearch();window.toggleMarkingFeature();}}>
                        <Edit3 className="nav-icon" />
                    </div>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltipAusblenden}>
                    <div className="nav-icon-wrapper"  onClick={function(){window.hideSearch();window.toggleLeretoFeature(); }}>
                        <Eye className="nav-icon" id="eye"/>
                        <EyeOff className="nav-icon" style={{display: 'none'}} id="eyeoff"/>
                    </div>
                </OverlayTrigger>
    <OverlayTrigger
placement="left"
delay={{ show: 250, hide: 250 }}
overlay={renderTooltipLogout}>
    <div className="nav-icon-wrapper" onClick={logOut}>
    <LogOut className="nav-icon" />
    </div>
    </OverlayTrigger>

            </Col>

            <VoucherModal isVisible={isVisible} hide={hideElement}>
            </VoucherModal>

            <UserprofileModal isVisible={isVisibleModal} hide={hideElementModal} data={profile}>
            </UserprofileModal>

        </Container>
    )
}

export default GreyHeader
