export const HOST_URL = 'https://api.books.lereto.com'
export const SIGNUP_URL = '/api/user/register/'
export const PASSWORD_CHANGE_URL = '/api/users/change-password/'
export const LOGIN_URL = '/api/auth-token/'
export const VOUCHER_URL = '/api/product/gutschein/add-gutschein/'
export const BOOKS_URL = '/api/product/book/'
export const PASSWORD_RESET_URL = '/api/user/reset-password/'
export const FACULTAS_LINK = 'https://www.facultas.at/home'

export const EMAIL_REGEX = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
)

export const STUDENT_ID_REGEX = RegExp(
    /^[0-9]*$/
)
